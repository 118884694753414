const DOOR_BODY = 548;
const DOOR_FRAME = 549;
const DOOR_CLOSER = 550;
const HINGES = 551;
const LOCK_LATCH = 552;
const STRIP = 553;
const GAP = 554;
const GLAZING = 555;
const THRESHOLD = 556;
const DOOR_SIGN = 571;
const DOOR_CLOSE_HOLD_POSITION = 572;
const PHOTOGRAPH_ONE = 567; 
const PHOTOGRAPH_TWO = 568;
const PHOTOGRAPH_THREE = 611;
const PHOTOGRAPH_FOUR = 612;
const PHOTOGRAPH_FIVE = 613;
const PHOTOGRAPH_SIX = 614;
const PHOTOGRAPH_SEVEN = 615;
const PHOTOGRAPH_EIGHT = 616;
const PHOTOGRAPH_NINE = 617;
const PHOTOGRAPH_TEN = 618;

const PHOTO_FIELDS = {
    'photograph_1': PHOTOGRAPH_ONE,
    'photograph_2': PHOTOGRAPH_TWO,
    'photograph_3': PHOTOGRAPH_THREE,
    'photograph_4': PHOTOGRAPH_FOUR,
    'photograph_5': PHOTOGRAPH_FIVE,
    'photograph_6': PHOTOGRAPH_SIX,
    'photograph_7': PHOTOGRAPH_SEVEN,
    'photograph_8': PHOTOGRAPH_EIGHT,
    'photograph_9': PHOTOGRAPH_NINE,
    'photograph_10': PHOTOGRAPH_TEN,
}

export default {
    DOOR_BODY,
    DOOR_FRAME,
    DOOR_CLOSER,
    HINGES,
    LOCK_LATCH,
    STRIP,
    GAP,
    GLAZING,
    THRESHOLD,
    DOOR_SIGN,
    DOOR_CLOSE_HOLD_POSITION,
    PHOTOGRAPH_ONE,
    PHOTOGRAPH_TWO,
    PHOTOGRAPH_THREE,
    PHOTOGRAPH_FOUR,
    PHOTOGRAPH_FIVE,
    PHOTOGRAPH_SIX,
    PHOTOGRAPH_SEVEN,
    PHOTOGRAPH_EIGHT,
    PHOTOGRAPH_NINE,
    PHOTOGRAPH_TEN,
    PHOTO_FIELDS
};