import React, { Fragment, useState, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import Cookie from 'js-cookie';
import axios from 'axios';
import Localbase from 'localbase';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import doorSections from '../../constants/doorSections';
import Photo from '../../components/Photo';

import styles from './styles/PhotoManagement.module.css';

export default function PhotoManagement() {
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [deleteAllLoading, setDeleteAllLoading] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [uploadedList, setUploadedList] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [uploadMessage, setUploadMessage] = useState('');
    const [totalUploaded, setTotalUploaded] = useState(0);

    const db = new Localbase('napfis-webapp');

    const handleUploadAll = async () => {
        setUploadedList([]);
        setErrorList([]);
        setUploading(true);
        setTotalUploaded(0);

        const filesToBeUploaded = photos.reduce((acc, current) => {
            return current?.uploaded !== true ? acc + 1 : acc
        }, 0);

        if (filesToBeUploaded) {
            setUploadMessage(`of ${filesToBeUploaded}`);
        } else {
            setUploadMessage('All photos have been uploaded.');
        }

        let generatedResponse = []
        await Promise.all(photos.map(async (photo) => {
            if (!photo?.uploaded) {
                try {
                    const fd = new FormData();
                    const fieldName = photo.name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;
                
                    fd.append(fieldName, photo.photo);
    
                    let insertResponse = await axios.post('https://test.napfis.co.uk/app_handler.php', fd, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'multipart/form-data',
                            'WG-Method': 'SAVE_DOOR_PHOTO',
                            'Wg-Doorid': photo.id,
                            'Wg-Key': Cookie.get('accessToken')
                        }
                    }); 
                    // and response need to be added into final response array 
                    generatedResponse.push(insertResponse);
    
                    setUploadedList(prev => [...prev, { id: photo.id, name: photo.name }]);
    
                    setTotalUploaded(prev => prev + 1);
        
                    await db.collection('door-photos').doc({ id: photo.id }).update({
                        uploaded: true,
                    });
                } catch (error) {
                    setTotalUploaded(prev => prev + 1);
    
                    setErrorList(prev => [...prev, { id: photo.id, name: photo.name }]);
                }
            }
        }));

        console.log('complete all') // gets loged first
        console.log(generatedResponse);

        // photos.forEach(async (photo) => {
        //     if (!photo?.uploaded) {
        //         try {
        //             const fd = new FormData();
        //             const fieldName = photo.name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;
            
        //             fd.append(fieldName, photo.photo);
                    
        //             const response = await axios.post('https://test.napfis.co.uk/app_handler.php', fd, {
        //                 headers: {
        //                     'Access-Control-Allow-Origin': '*',
        //                     'Content-Type': 'multipart/form-data',
        //                     'WG-Method': 'SAVE_DOOR_PHOTO',
        //                     'Wg-Doorid': photo.id,
        //                     'Wg-Key': Cookie.get('accessToken')
        //                 }
        //             });

        //             if (response.status === 200) {
        //                 setUploadedList(prev => [...prev, { id: photo.id, name: photo.name }]);

        //                 setTotalUploaded(prev => prev + 1);
    
        //                 await db.collection('door-photos').doc({ id: photo.id }).update({
        //                     uploaded: true,
        //                 });
        //             } else {
        //                 setErrorList(prev => [...prev, { id: photo.id, name: photo.name }]);
        //             }
        //         } catch (error) {
        //             setErrorList(prev => [...prev, { id: photo.id, name: photo.name }]);
        //         }
        //     }
        // });

        setUploading(false);
    }

    const handleDeleteAll = async () => {
        setUploadedList([]);
        setErrorList([]);
        setDeleteAllLoading(true);

        await db.collection('door-photos').delete();
        
        setDeleteAllLoading(false);
        setPhotos([]);
    }

    useEffect(() => {
        async function getPhotographs() {
            setLoading(true);
    
            const photos = await db.collection('door-photos').get();
    
            setPhotos(photos || []);
            setLoading(false);
        }

        getPhotographs();
    }, []);

    useEffect(() => {
        // Check status
        async function getPhotographUploadedStatus() {
            let statusCount = 0;

            photos.forEach(async (photo) => {
                if (!photo?.uploaded && statusCount < 11) {
                    statusCount++;
                    
                    try {
                        const response = await axios.get('https://test.napfis.co.uk/app_handler.php', {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                'WG-Method': 'CHECK_PHOTO_STATUS',
                                'Wg-Doorid': photo.id,
                                'Wg-PhotoType': photo.name,
                                'Wg-Key': Cookie.get('accessToken')
                            }
                        });

                        if (response.status === 200) {
                            await db.collection('door-photos').doc({ id: photo.id }).update({
                                uploaded: true,
                            });
                        }
                    } catch (error) {

                    }
                }
            });
        }

        getPhotographUploadedStatus();
    }, [db]);

    return (
        <Fragment>
            <Header>Photo Management</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <h1>Photo Management</h1>
                {!loading ? (
                    <>
                        <p style={{ textAlign: 'center' }}>You have {photos.length} photographs stored on your device.</p>
                        <div className={styles.header}>
                            {photos.length > 0 && (
                                <>
                                    <div className={styles.uploadAllContainer}>
                                        <button className={styles.uploadAllButton} onClick={handleUploadAll} style={{ marginRight: '1rem' }}>Upload All<PulseLoader loading={uploading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                                        {uploading ? (<div style={{ marginLeft: '8px' }}>{totalUploaded}{uploadMessage}</div>) : null}
                                    </div>
                                    <button className={styles.deleteAllButton} onClick={handleDeleteAll}>Delete All<PulseLoader loading={deleteAllLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                                </>
                            )}
                        </div>
                        <div className={styles.photoContainer}>
                            {photos.map((photo) => {
                                console.log(photo)
                                const uploaded = uploadedList.find((item) => item.id === photo.id && item.name === photo.name);
                                const error = errorList.find((item) => item.id === photo.id && item.name === photo.name);

                                return (
                                    <Photo key={photo.id} id={photo.id} name={photo.name} photo={photo.photo} isUploaded={photo.uploaded} uploaded={uploaded} error={error} />
                                );
                            })}
                        </div>
                    </>
                ) : (                    
                    <div className={styles.loadingContainer}>
                        <ClipLoader loading={loading} color={'#000'} css={'margin-left: 8px'} size={25} />
                        <div className={styles.loadingText}>Loading photographs...</div>
                    </div>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
