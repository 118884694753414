import axios from 'axios';
import Cookie from 'js-cookie';

import { GET_RECENT_SURVEYS_REQUEST, GET_RECENT_SURVEYS_SUCCESS, GET_RECENT_SURVEYS_FAIL, REMOVE_SURVEY_REQUEST, REMOVE_SURVEY_SUCCESS, REMOVE_SURVEY_FAILURE } from '../types/dashboardTypes';

export const getRecentSurveys = () => async dispatch => {
    let surveys = [];

    const savedSurveys = JSON.parse(localStorage.getItem('survey'));
    const clientData = JSON.parse(localStorage.getItem('clientData'));

    if (savedSurveys) {
        const surveyKeys = Object.keys(savedSurveys).filter((key) => savedSurveys[key]?.type === 'offline');

        surveyKeys.forEach((key) => {
            let data = {};
            let doors = 0;
            if (savedSurveys[key]?.doors) {
                doors = Object.keys(savedSurveys[key]?.doors).length;
            }

            if (clientData[key]) {
                data = clientData[key];
            }
            surveys.push({ ...data, surveyId: key, type: 'offline', inspection_doors: doors, survey_status: 'In Progress' });
        });
    }
    
    try {
        dispatch({ type: GET_RECENT_SURVEYS_REQUEST });


        const { data } = await axios.get('https://test.napfis.co.uk/app_handler.php', { headers: { 'WG-Method': 'GET_RECENT_SURVEYS', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: GET_RECENT_SURVEYS_SUCCESS, payload: { recent: data?.surveys, local: surveys } });

        localStorage.setItem('recentSurveys', JSON.stringify(data?.surveys));
    } catch (error) {
        const recentSurveys = JSON.parse(localStorage.getItem('recentSurveys')) || [];

        dispatch({ type: GET_RECENT_SURVEYS_FAIL, payload: { recent: recentSurveys, local: surveys } });
    }
};

export const removeSurvey = (key) => async dispatch => {
    try {
        dispatch({ type: REMOVE_SURVEY_REQUEST });

        const { data } = await axios.post('https://test.napfis.co.uk/app_handler.php', { survey_key: key }, { headers: { 'WG-Method': 'REMOVE_SURVEY', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: REMOVE_SURVEY_SUCCESS, payload: key });

        let recentSurveys = JSON.parse(localStorage.getItem('recentSurveys'));

        if (recentSurveys) {
            const newRecentSurveys = recentSurveys.filter((survey) => survey.survey_secret_key !== key);

            localStorage.setItem('recentSurveys', JSON.stringify(newRecentSurveys));
        }

    } catch (error) {
        dispatch({ type: REMOVE_SURVEY_FAILURE });
    }
}