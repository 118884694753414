import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import axios from 'axios';
import Localbase from 'localbase';
import Cookie from 'js-cookie';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { FAILED_DOOR_PHOTO, SET_SURVEY_COMPLETE, UPDATE_SURVEY_KEY } from '../../types/surveyTypes';

import answers from '../../constants/answers';
import doorSections from '../../constants/doorSections';
import styles from './styles/SurveySummary.module.css';

export const SurveySummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [surveyResult, setSurveyResult] = useState(null);
    const [cost, setCost] = useState(0.00);
    const [totalPassedDoors, setTotalPassedDoors] = useState(0);
    const [totalFailedDoors, setTotalFailedDoors] = useState(0);
    const [totalPassed, setTotalPassed] = useState(0);
    const [totalFailed, setTotalFailed] = useState(0);
    const [totalCost, setTotalCost] = useState(0.00);
    const [adjustment, setAdjustment] = useState(0);
    const [filteredCosts, setFilteredCosts] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const surveySelector = useSelector((state) => state.survey);
    const { id, recordId, includeCosts } = surveySelector;

    const doorSelector = useSelector((state) => state.door);
    const { doors } = doorSelector;
    
    const costsSelector = useSelector((state) => state.costs);
    const { costs } = costsSelector;

    const numOfDoors = Object.values(doors).filter((door) => !door.deleted && door.leftId === null).length;

    const saveSummary = async (tmpRecordId) => {
        const summary = { inspection_total: totalCost, inspection_adjustment: adjustment, inspection_initial_cost: cost };

        const savedSummary = JSON.parse(localStorage.getItem('summary'));
        const tmpSummary = { ...savedSummary, [id]: summary };
        localStorage.setItem('summary', JSON.stringify(tmpSummary));

        try {
            await axios.post('https://test.napfis.co.uk/app_handler.php', summary, { headers: { 'Wg-Method': 'SAVE_SURVEY_SUMMARY', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': tmpRecordId }})
        } catch (error) {
            console.error(error);
        }

    }

    const handleCompleteSurvey = async () => {
        setLoading(true);

        let savedSurvey = JSON.parse(localStorage.getItem('survey'));
        const clientData = JSON.parse(localStorage.getItem('clientData'));

        let tmpRecordId = 0;
        let promises = [];

        if (clientData) {
            const clientFormData = new FormData();

            clientFormData.append('inspection_client_name', clientData[id]?.inspection_client_name);
            clientFormData.append('inspection_client_telephone', clientData[id]?.inspection_client_telephone);
            clientFormData.append('inspection_client_email', clientData[id]?.inspection_client_email);
            clientFormData.append('inspection_survey_address', clientData[id]?.inspection_survey_address);
            clientFormData.append('inspection_comments', clientData[id]?.inspection_comments);
            clientFormData.append('inspection_include_costs', clientData[id]?.inspection_include_costs);

            if (recordId === 0) {
                try {
                    const { data: surveyData } = await axios.post('https://test.napfis.co.uk/app_handler.php', clientFormData, { headers: { 'Wg-Method': 'SAVE_CLIENTDATA', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': recordId }});
                    
                    tmpRecordId = surveyData?.record_id;
        
                    if (surveyData?.survey_key) {
                        dispatch({ type: UPDATE_SURVEY_KEY, payload: surveyData?.survey_key });
        
                        savedSurvey[id].surveyKey = surveyData?.survey_key;
                        localStorage.setItem('survey', JSON.stringify(savedSurvey));
                    }
                } catch (error) {
                    setError('The survey wasn\'t fully uploaded. Please complete the survey when you have an Internet connection.');
                    setLoading(false);
                }
            } else {
                tmpRecordId = recordId;
            }
        }

        const db = new Localbase('napfis-webapp');
        const documents = await db.collection('door-photos').get();

        Object.keys(doors).forEach(async (doorId) => {
            const doorRecordId = doors[doorId]?.recordId;
            
            const filteredDocuments = documents.filter((document) => document.id === doorId);

            if (doors[doorId]) {
                const body = { ...doors[doorId], numOfPhotos: filteredDocuments.length }
                promises.push(axios.post('https://test.napfis.co.uk/app_handler.php', body, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'WG-Surveyid': tmpRecordId,
                        'Wg-Doorid': doorRecordId,
                        'Wg-Method': 'SAVE_DOOR_V2',
                        'Wg-Key': Cookie.get('accessToken')
                    }
                }));
            }
        });

        Object.keys(doors).forEach(async (doorId) => {
            if (doors[doorId] && !doors[doorId]?.deleted) {
                try {
                    const filteredDocuments = documents.filter((document) => document.id === doorId && !document?.uploaded);

                    filteredDocuments.forEach(async (document) => {
                        const fd = new FormData();

                        fd.append(doorSections.PHOTO_FIELDS[document.name], document?.photo);

                        promises.push(axios.post('https://test.napfis.co.uk/app_handler.php', fd, {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'multipart/form-data',
                                'WG-Method': 'SAVE_DOOR_PHOTO',
                                'Wg-Doorid': doorId,
                                'Wg-Key': Cookie.get('accessToken')
                            }
                        }));
                    });
                    
                } catch (error) {
                    dispatch({ type: FAILED_DOOR_PHOTO, payload: doorId });
                }
            }
        });

        axios.all(promises)
            .then(axios.spread((...args) => {
                saveSummary(tmpRecordId);
                completeSurvey();
            }))
            .catch((error) => {
                setError('The survey wasn\'t fully uploaded. Please complete the survey when you have an Internet connection.');
                setLoading(false);
            });
    }

    const completeSurvey = () => {
        dispatch({ type: SET_SURVEY_COMPLETE });
        history.push('/survey/complete');
    };

    const handleBack = () => {
        history.push('/survey/doors');
    };

    useEffect(() => {
        const doorsFiltered = Object.values(doors).map((door) => {
            if (!door.deleted && door.leftId === null) {
                let tmpPassed = door.answers.reduce((acc, current) => {
                    return current.answer === answers.PASSED ? acc + 1 : acc
                }, 0);
                let tmpFailed = door.answers.reduce((acc, current) => {
                    return current.answer === answers.FAILED ? acc + 1 : acc
                }, 0);
                let tmpTotal = door.answers.reduce((acc, current) => {
                    return current.answer === answers.FAILED ? acc + parseFloat(filteredCosts[current.id]) : acc
                }, 0.00);
                let tmpOther = parseFloat(door.other.cost);

                const rightDoor = Object.values(doors).find((d) => d.leftId === door.id);

                if (rightDoor) {
                    tmpPassed += rightDoor.answers.reduce((acc, current) => {
                        return current.answer === answers.PASSED ? acc + 1 : acc
                    }, 0);
                    tmpFailed += rightDoor.answers.reduce((acc, current) => {
                        return current.answer === answers.FAILED ? acc + 1 : acc
                    }, 0);
                    tmpTotal += rightDoor.answers.reduce((acc, current) => {
                        return current.answer === answers.FAILED ? acc + parseFloat(filteredCosts[current.id]) : acc
                    }, 0.00);
                    tmpOther += parseFloat(rightDoor.other.cost);
                }

                return {
                    id: door.id,
                    ref: door.ref,
                    passed: tmpPassed,
                    failed: tmpFailed,
                    total: tmpTotal,
                    other: tmpOther
                }
            }

            return null;
        });

        const doorResults = doorsFiltered.filter((door) => door !== null );

        setSurveyResult(doorResults.find((door) => door.failed > 0));
        setTotalPassedDoors(doorResults.reduce((acc, current) => current.failed === 0 ? acc + 1 : acc, 0));
        setTotalFailedDoors(doorResults.reduce((acc, current) => current.failed > 0 ? acc + 1 : acc, 0));
        setTotalPassed(doorResults.reduce((acc, current) => acc + current.passed, 0));
        setTotalFailed(doorResults.reduce((acc, current) => acc + current.failed, 0));
        const total = doorResults.reduce((acc, current) => acc + current.total, 0.00) + doorResults.reduce((acc, current) => acc + current.other, 0.00);
        
        setCost(total);
        setTotalCost(total);
        setAdjustment(0);
    }, [doors, filteredCosts]);

    useEffect(() => {
        if (adjustment) {
            setTotalCost(parseFloat((cost + ((cost / 100) * parseInt(adjustment)))).toFixed(2));
        }
    }, [adjustment, cost]);

    useEffect(() => {
        let tmp = {};

        Object.keys(costs).forEach((key) => tmp[key.slice(key.length - 3)] = costs[key]);

        setFilteredCosts(tmp);
    }, [costs]);

    return (
        <Fragment>
            <Header>Survey Summary</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className="form__buttons">
                    <button className="form__button secondary" onClick={handleBack}>Back</button>
                </div>
                <div className={styles.surveyResultContainer}>
                    {surveyResult ? <Cross className={styles.failed} /> : <Check className={styles.passed} /> }
                </div>
                <div className={styles.surveyDoorsContainer}>
                    <p style={{ fontWeight: '600' }}>{!surveyResult ? 'Please review the cost summary below' : 'There are items that require attention. A summary can be found below.'}</p>
                    <div>No. of Doors: {numOfDoors}</div>
                    <div>No. of Passed Doors: {totalPassedDoors}</div>
                    <div>No. of Failed Doors: {totalFailedDoors}</div>
                    <div>No. of Passed Questions: {totalPassed}</div>
                    <div>No. of Failed Questions: {totalFailed}</div>
                </div>
                
                {includeCosts === '1' ? (
                    <>
                        <div className={styles.surveyTotalContainer}>
                            <div className={styles.surveyTotalRow}>
                                <div>Cost:</div>
                                <div>£{cost}</div>
                            </div>
                            <div className={styles.surveyTotalRow}>
                                <div>Adjustment</div>
                                <div><input type="number" value={adjustment} onChange={(e) => setAdjustment(e.target.value)} /> %</div>
                            </div>
                            <div className={`${styles.surveyTotalRow} ${styles.total}`}>
                                <div>Total Cost:</div>
                                <div>£{totalCost}</div>
                            </div>
                        </div>
                    </>
                ) : null}

                <p>Please ensure you've completed all necassary changes before you proceed. After you've completed the survey you cannot go back and make further changes.</p>
                    
                <div className="form__buttons">
                    <button className="form__button form__button--inline" disabled={loading} onClick={handleCompleteSurvey}>Complete Survey <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
                {error && (<div className="error" style={{ textAlign: 'center' }}>{error}</div>)}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
