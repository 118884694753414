export const CREATE_SURVEY_REQUEST = 'CREATE_SURVEY_REQUEST';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAILURE = 'CREATE_SURVEY_FAILURE';

export const LOAD_SURVEY_REQUEST = 'LOAD_SURVEY_REQUEST';
export const LOAD_SURVEY_SUCCESS = 'LOAD_SURVEY_SUCCESS';
export const LOAD_SURVEY_FAILURE = 'LOAD_SURVEY_FAILURE';

export const UPDATE_SURVEY_REQUEST = 'UPDATE_SURVEY_REQUEST';
export const UPDATE_SURVEY_SUCCESS = 'UPDATE_SURVEY_SUCCESS';
export const UPDATE_SURVEY_FAILURE = 'UPDATE_SURVEY_FAILURE';

export const SAVE_DOOR_REQUEST = 'SAVE_DOOR_REQUEST';
export const SAVE_DOOR_SUCCESS = 'SAVE_DOOR_SUCCESS';
export const SAVE_DOOR_FAILURE = 'SAVE_DOOR_FAILURE';

export const LOAD_DOOR_REQUEST = 'LOAD_DOOR_REQUEST';
export const LOAD_DOOR_SUCCESS = 'LOAD_DOOR_SUCCESS';
export const LOAD_DOOR_FAILURE = 'LOAD_DOOR_FAILURE';

export const ADD_DOOR_REQUEST = 'ADD_DOOR_REQUEST';
export const ADD_DOOR_SUCCESS = 'ADD_DOOR_SUCCESS';
export const ADD_DOOR_FAILURE = 'ADD_DOOR_FAILURE';

export const REMOVE_DOOR_REQUEST = 'REMOVE_DOOR_REQUEST';
export const REMOVE_DOOR_SUCCESS = 'REMOVE_DOOR_SUCCESS';
export const REMOVE_DOOR_FAILURE = 'REMOVE_DOOR_FAILURE';

export const REMOVE_ALL_DOORS_REQUEST = 'REMOVE_ALL_DOORS_REQUEST';
export const REMOVE_ALL_DOORS_SUCCESS = 'REMOVE_ALL_DOORS_SUCCESS';
export const REMOVE_ALL_DOORS_FAILURE = 'REMOVE_ALL_DOORS_FAILURE';

export const ASSIGN_PINS_REQUEST = 'ASSIGN_PINS_REQUEST';
export const ASSIGN_PINS_SUCCESS = 'ASSIGN_PINS_SUCCESS';
export const ASSIGN_PINS_FAILURE = 'ASSIGN_PINS_FAILURE';

export const SET_ACTIVE_PLAN_REQUEST = 'SET_ACTIVE_PLAN_REQUEST';
export const SET_ACTIVE_PLAN_SUCCESS = 'SET_ACTIVE_PLAN_SUCCESS';
export const SET_ACTIVE_PLAN_FAILURE = 'SET_ACTIVE_PLAN_FAILURE';

export const SAVE_DOOR_REF_REQUEST = 'SAVE_DOOR_REF_REQUEST';
export const SAVE_DOOR_REF_SUCCESS = 'SAVE_DOOR_REF_SUCCESS';
export const SAVE_DOOR_REF_FAILURE = 'SAVE_DOOR_REF_FAILURE';

export const CLEAR_DOOR_PROCEED = 'CLEAR_DOOR_PROCEED';
export const CLEAR_SURVEY = 'CLEAR_SURVEY';

export const SET_SURVEY_COMPLETE = 'SET_SURVEY_COMPLETE';

export const UPDATE_SURVEY_KEY = 'UPDATE_SURVEY_KEY';

export const CLEAR_DOORLIST_PROCEED = 'CLEAR_DOORLIST_PROCEED';
export const CLEAR_PDF_SELECTOR = 'CLEAR_PDF_SELECTOR';
export const SET_PDF_SELECTOR_TRUE = 'SET_PDF_SELECTOR_TRUE';

export const UPDATE_TOTAL_FIELDS = 'UPDATE_TOTAL_FIELDS';

export const FAILED_DOOR_PHOTO = 'FAILED_DOOR_PHOTO';
export const REMOVE_FAILED_DOOR_PHOTO = 'REMOVE_FAILED_DOOR_PHOTO';
export const CLEAR_FAILED_DOOR_PHOTOS = 'CLEAR_FAILED_DOOR_PHOTOS';