import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Localbase from 'localbase';

import Header from '../layout/Header';
import Menu from '../layout/Menu';
import BottomNav from '../layout/BottomNav';
import Content from '../layout/Content';
import { DoorViewQuestion } from '../DoorViewQuestion';

import {
    saveSection,
    addDoor,
    saveRef,
    saveType,
    removeDoor,
    restoreDoor,
    savePhoto,
    removePhoto,
    saveOther,
    saveDoorToLocalStorage,
    saveFailedOption,
    saveFailedOther,
} from '../../actions/doorActions';

import styles from './styles/DoorView.module.css';
import answers from '../../constants/answers';
import {
    LEFT_DOOR_MARKERS,
    RIGHT_DOOR_MARKERS,
} from '../../constants/dimensions';
import { removeDoorPhoto, saveDoorPhoto } from '../../functions/utils';
import doorSections from '../../constants/doorSections';
import {
    DOOR_LEFT,
    DOOR_LEFT_NEXT,
    DOOR_RIGHT,
    DOOR_RIGHT_NEXT,
    PHOTOGRAPH_ONE,
} from '../../constants/images';

export const DoorView = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const doorSelector = useSelector((state) => state.door);
    const { doors, otherLoading, saveToLocalStorage } = doorSelector;

    const doorsArray = Object.values(doors);

    const doorKeys = doorsArray
        .filter((door) => {
            return !door.deleted;
        })
        .map((door) => door.id);

    const appSelector = useSelector((state) => state.app);
    const { sections } = appSelector;

    const surveySelector = useSelector((state) => state.survey);
    const { id: surveyId, includeCosts } = surveySelector;

    const currentDoor = doors[id];

    const isRightDoor = currentDoor?.leftId ? true : false;
    const doorAnswers = currentDoor?.answers;

    const [doorRef, setDoorRef] = useState(
        currentDoor?.ref === id ? '' : currentDoor?.ref
    );
    const [doorType, setDoorType] = useState(currentDoor?.type || '');
    const [activeSection, setActiveSection] = useState(null);
    const [showControls, setShowControls] = useState(false);
    const [finishDoor, setFinishDoor] = useState(false);
    const [nextDoor, setNextDoor] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [otherInfo, setOtherInfo] = useState(false);
    const [otherText, setOtherText] = useState(currentDoor.other.text);
    const [otherCost, setOtherCost] = useState(currentDoor.other.cost);
    const [photoPreviews, setPhotoPreviews] = useState({
        photograph_1: '',
        photograph_2: '',
        photograph_3: '',
        photograph_4: '',
        photograph_5: '',
        photograph_6: '',
        photograph_7: '',
        photograph_8: '',
        photograph_9: '',
        photograph_10: '',
    });
    const [nextPhotoIndex, setNextPhotoIndex] = useState('');

    const photoInputRef = useRef(null);

    const isPhotosEmpty = Object.values(photoPreviews).some(value => value === '');

    let rightDoorID = null;
    let leftDoorID = null;
    let rightDoor = Object.values(doors).find(
        (door) => door.leftId === id && !door.deleted
    );

    if (rightDoor) {
        rightDoorID = rightDoor.id;
        leftDoorID = rightDoor.leftId;
    } else {
        rightDoor = Object.values(doors).find(
            (door) => door.id === id && door.leftId !== null && !door.deleted
        );

        if (rightDoor) {
            const leftDoor = Object.values(doors).find(
                (door) => door.id === rightDoor?.leftId && !door.deleted
            );

            if (leftDoor) {
                rightDoorID = rightDoor.id;
                leftDoorID = rightDoor.leftId;
            }
        }
    }

    const currentDoorIndex = doorKeys.indexOf(id);
    const nextDoorId = doorKeys[currentDoorIndex + 1];

    const isActiveLeftDoor = id === leftDoorID ? true : false;
    const isActiveRightDoor = id === rightDoorID ? true : false;

    const handleDoorRefBlur = () => {
        dispatch(saveRef(id, doorRef));
    };

    const handleAddPhoto = async (photograph, event) => {
        const { files } = event.target;

        setPhotoPreviews((prev) => ({ ...prev, [photograph]: URL.createObjectURL(files[0])}));

        await saveDoorPhoto(id, surveyId, photograph, files[0]);

        const formData = new FormData();
        formData.append(doorSections.PHOTO_FIELDS[photograph], files[0]);

        dispatch(savePhoto(id, photograph, formData));

        photoInputRef.current.value = '';
    };

    const handleRemovePhoto = async (id, photograph) => {
        removeDoorPhoto(id, photograph);

        dispatch(removePhoto(id, doorSections.PHOTO_FIELDS[photograph]));

        const updatedPhotoPreviews = { ...photoPreviews, [photograph]: '' };

        const nonEmptyValues = Object.values(updatedPhotoPreviews).filter(value => value !== '');

        // Create a new state object with the non-empty values shifted
        const updatedPreviews = {};
        Object.keys(photoPreviews).forEach((key, index) => {
            updatedPreviews[key] = nonEmptyValues[index] || '';
        });

        console.log(updatedPreviews);

        setPhotoPreviews(updatedPreviews);
    };

    const nextQuestion = () => {
        if (activeSection) {
            const index = sections.findIndex(
                (question) => question.id === activeSection.id
            );
            let nextIndex = 0;

            if (sections.length > index + 1) {
                nextIndex = index + 1;
                setActiveSection(sections[nextIndex]);
            } else {
                setActiveSection(null);
            }
        }
    };

    const handleOtherInfoSave = () => {
        dispatch(saveOther(id, otherText, otherCost));
    };

    const handleAnswerQuestion = (sectionId, answer) => {
        dispatch(saveSection(id, sectionId, answer));
        nextQuestion();
    };

    const handleFailedOption = (sectionId, option) => {
        dispatch(saveFailedOption(id, sectionId, option));
    };

    const handleSaveFailedOther = (sectionId, other) => {
        dispatch(saveSection(id, sectionId, answers.FAILED));
        dispatch(saveFailedOther(id, sectionId, other));
        nextQuestion();
    };

    const handleCloseQuestion = () => {
        setActiveSection(null);
        setShowControls(false);
    };

    const handleDoorType = (type) => {
        setDoorType(type);

        if ((type === '318' || type === '320') && !rightDoor) {
            const rightDoorExists = Object.values(doors).find(
                (door) => door.leftId === id && door.deleted
            );

            if (rightDoorExists) {
                dispatch(restoreDoor(rightDoorExists.id));
            } else {
                dispatch(addDoor(id, type, doorRef));
            }
        }

        if ((type === '317' || type === '319') && rightDoor) {
            dispatch(removeDoor(rightDoor.id));
        }

        dispatch(saveType(id, type));
    };

    useEffect(() => {
        if (isCompleted) {
            if (nextDoorId) {
                setNextDoor(true);
            }

            setFinishDoor(true);
            setShowControls(false);
        }
    }, [nextDoorId, isCompleted, id]);

    useEffect(() => {
        const unansweredQuestions = doors[id].answers.filter(
            (answer) => answer.answer === answers.NOT_ANSWERED
        );

        if (unansweredQuestions.length > 0) {
            setIsCompleted(false);
        } else {
            setIsCompleted(true);
        }
    }, [doors, id]);

    useEffect(() => {
        setActiveSection(null);
    }, [id]);

    useEffect(() => {
        if (currentDoor) {
            setDoorRef(currentDoor?.ref === id ? '' : currentDoor?.ref);
            setDoorType(currentDoor?.type || '');
            setOtherText(currentDoor.other.text);
            setOtherCost(currentDoor.other.cost);
        }
    }, [currentDoor, id]);

    useEffect(() => {
        if (activeSection) {
            setShowControls(true);
        } else {
            setShowControls(false);
        }
    }, [activeSection]);

    useEffect(() => {
        async function loadPhotos() {
            const db = new Localbase('napfis-webapp');

            try {
                db.collection('door-photos')
                    .get()
                    .then((documents) => {
                        let filteredDocuments = documents.filter((document) => document.id === id);
                        
                        let generatePhotoPreviews = {
                            photograph_1: '',
                            photograph_2: '',
                            photograph_3: '',
                            photograph_4: '',
                            photograph_5: '',
                            photograph_6: '',
                            photograph_7: '',
                            photograph_8: '',
                            photograph_9: '',
                            photograph_10: '',
                        };

                        filteredDocuments.forEach((photo) => {
                            generatePhotoPreviews[photo.name] = URL.createObjectURL(photo.photo);
                        });

                        setPhotoPreviews(generatePhotoPreviews);
                    });
            } catch (error) {
                console.error(error);
            }
        }

        loadPhotos();
    }, [id]);

    useEffect(() => {
        if (saveToLocalStorage) {
            dispatch(saveDoorToLocalStorage());
        }
    }, [saveToLocalStorage, dispatch]);

    useEffect(() => {
        let nextEmptyPhotoIndex;

        for (let x = 0; x < Object.keys(photoPreviews).length; x++) {
            const index = Object.keys(photoPreviews)[x];

            if (photoPreviews[index] === '') {
                nextEmptyPhotoIndex = index;

                break;
            }
        }

        setNextPhotoIndex(nextEmptyPhotoIndex);
    }, [photoPreviews]);

    return (
        <>
            <Header />
            <Menu />
            <Content style={{ marginTop: '100.27px' }}>
                <div>
                    {showControls && activeSection && (
                        <DoorViewQuestion
                            section={activeSection}
                            doorId={id}
                            onAnswerQuestion={handleAnswerQuestion}
                            onFailedOption={handleFailedOption}
                            onFailedOther={handleSaveFailedOther}
                            onShowControls={handleCloseQuestion}
                        />
                    )}
                    {/* Door Image */}

                    <div className={styles.doorContainer}>
                        <div className={styles.doorViewContainer}>
                            {isRightDoor && leftDoorID && (
                                <Link to={`/survey/doors/${leftDoorID}`}>
                                    <div className={styles.leftDoorNext}>
                                        <img
                                            src={`data:image/jpeg;base64,${DOOR_LEFT_NEXT}`}
                                            alt='Left door next'
                                        />
                                    </div>
                                </Link>
                            )}
                            <div className={styles.imageContainer}>
                                {isRightDoor ? (
                                    <>
                                        <img
                                            src={`data:image/jpeg;base64,${DOOR_RIGHT}`}
                                            alt='Right door'
                                        />
                                        {RIGHT_DOOR_MARKERS.map((marker) => {
                                            const currentAnswer =
                                                doorAnswers.find(
                                                    (answer) =>
                                                        answer.id === marker.id
                                                )?.answer;
                                            return marker.id === 572 ? (
                                                <div
                                                    key={marker.id}
                                                    className={`${styles.clickableTriangle} ${currentAnswer}-triangle`}
                                                    style={{
                                                        top: marker.top,
                                                        left: marker.left,
                                                    }}
                                                    onClick={() =>
                                                        setActiveSection(
                                                            sections.find(
                                                                (section) =>
                                                                    section.id ===
                                                                    marker.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    {currentAnswer === 'NA' && (
                                                        <span
                                                            className={
                                                                styles.iconStartPosition
                                                            }
                                                        >
                                                            NA
                                                        </span>
                                                    )}
                                                    <svg
                                                        height='100'
                                                        width='100'
                                                    >
                                                        <polygon
                                                            points='50,20 16,80 84,80'
                                                            className='triangle'
                                                        />
                                                    </svg>
                                                    {currentAnswer ===
                                                        'NOT_ANSWERED' && (
                                                        <span
                                                            className={
                                                                styles.startPosition
                                                            }
                                                        >
                                                            Start
                                                        </span>
                                                    )}
                                                    {currentAnswer ===
                                                        'PASSED' && (
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.iconStartPosition
                                                            }
                                                            icon={faCheck}
                                                        />
                                                    )}
                                                    {currentAnswer ===
                                                        'FAILED' && (
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.iconStartPosition
                                                            }
                                                            icon={faTimes}
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    key={marker.id}
                                                    className={`${
                                                        styles.clickable
                                                    } ${currentAnswer} ${
                                                        marker.id ===
                                                        activeSection?.id
                                                            ? styles.activeMarker
                                                            : ''
                                                    }`}
                                                    style={{
                                                        top: marker.top,
                                                        left: marker.left,
                                                    }}
                                                    onClick={() =>
                                                        setActiveSection(
                                                            sections.find(
                                                                (section) =>
                                                                    section.id ===
                                                                    marker.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    {currentAnswer ===
                                                        'PASSED' && (
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    )}
                                                    {currentAnswer ===
                                                        'FAILED' && (
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                        />
                                                    )}
                                                    {currentAnswer === 'NA' && (
                                                        <span>NA</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src={`data:image/jpeg;base64,${DOOR_LEFT}`}
                                            alt='Left door'
                                        />
                                        {LEFT_DOOR_MARKERS.map((marker) => {
                                            const currentAnswer =
                                                doorAnswers.find(
                                                    (answer) =>
                                                        answer.id === marker.id
                                                )?.answer;
                                            return marker.id === 572 ? (
                                                <div
                                                    key={marker.id}
                                                    className={`${styles.clickableTriangle} ${currentAnswer}-triangle`}
                                                    style={{
                                                        top: marker.top,
                                                        left: marker.left,
                                                    }}
                                                    onClick={() =>
                                                        setActiveSection(
                                                            sections.find(
                                                                (section) =>
                                                                    section.id ===
                                                                    marker.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    {currentAnswer === 'NA' && (
                                                        <span
                                                            className={
                                                                styles.iconStartPosition
                                                            }
                                                        >
                                                            NA
                                                        </span>
                                                    )}
                                                    <svg
                                                        height='100'
                                                        width='100'
                                                    >
                                                        <polygon
                                                            points='50,20 16,80 84,80'
                                                            className='triangle'
                                                        />
                                                    </svg>
                                                    {currentAnswer ===
                                                        'NOT_ANSWERED' && (
                                                        <span
                                                            className={
                                                                styles.startPosition
                                                            }
                                                        >
                                                            Start
                                                        </span>
                                                    )}
                                                    {currentAnswer ===
                                                        'PASSED' && (
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.iconStartPosition
                                                            }
                                                            icon={faCheck}
                                                        />
                                                    )}
                                                    {currentAnswer ===
                                                        'FAILED' && (
                                                        <FontAwesomeIcon
                                                            className={
                                                                styles.iconStartPosition
                                                            }
                                                            icon={faTimes}
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    key={marker.id}
                                                    className={`${
                                                        styles.clickable
                                                    } ${currentAnswer} ${
                                                        marker.id ===
                                                        activeSection?.id
                                                            ? styles.activeMarker
                                                            : ''
                                                    }`}
                                                    style={{
                                                        top: marker.top,
                                                        left: marker.left,
                                                    }}
                                                    onClick={() =>
                                                        setActiveSection(
                                                            sections.find(
                                                                (section) =>
                                                                    section.id ===
                                                                    marker.id
                                                            )
                                                        )
                                                    }
                                                >
                                                    {currentAnswer ===
                                                        'PASSED' && (
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    )}
                                                    {currentAnswer ===
                                                        'FAILED' && (
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                        />
                                                    )}
                                                    {currentAnswer === 'NA' && (
                                                        <span>NA</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                            {!isRightDoor && rightDoorID && (
                                <Link to={`/survey/doors/${rightDoorID}`}>
                                    <div className={styles.rightDoorNext}>
                                        <img
                                            src={`data:image/jpeg;base64,${DOOR_RIGHT_NEXT}`}
                                            alt='Right door next'
                                        />
                                    </div>
                                </Link>
                            )}
                        </div>
                        {/* Door Buttons */}
                        <div className={styles.doorButtons}>
                            <div>
                                <div className={styles.fieldBlock}>
                                    <input
                                        type='text'
                                        value={doorRef}
                                        placeholder='Door Reference'
                                        onChange={(e) =>
                                            setDoorRef(e.target.value)
                                        }
                                        onBlur={handleDoorRefBlur}
                                        disabled={isActiveRightDoor}
                                    />
                                </div>
                                <div
                                    className={styles.fieldBlock}
                                    style={{ marginTop: '1rem' }}
                                >
                                    <select
                                        value={doorType}
                                        onChange={(e) =>
                                            handleDoorType(e.target.value)
                                        }
                                        disabled={isActiveRightDoor}
                                    >
                                        <option value=''>
                                            Please select a door type
                                        </option>
                                        <option value='317'>
                                            FD30/s Single
                                        </option>
                                        <option value='318'>
                                            FD30/s Double
                                        </option>
                                        <option value='319'>
                                            FD60/s Single
                                        </option>
                                        <option value='320'>
                                            FD60/s Double
                                        </option>
                                    </select>
                                </div>

                                <div className={styles.doorSelectButtons}>
                                    {rightDoorID && (
                                        <>
                                            <Link
                                                className={`${
                                                    isActiveLeftDoor &&
                                                    styles.active
                                                } ${styles.doorButton}`}
                                                to={`/survey/doors/${leftDoorID}`}
                                            >
                                                Left
                                            </Link>
                                            <Link
                                                className={`${
                                                    isActiveRightDoor &&
                                                    styles.active
                                                } ${styles.doorButton}`}
                                                to={`/survey/doors/${rightDoorID}`}
                                            >
                                                Right
                                            </Link>
                                        </>
                                    )}
                                </div>
                                {otherInfo && (
                                    <div>
                                        <div
                                            className={styles.fieldBlock}
                                            style={{ marginTop: '1rem' }}
                                        >
                                            <textarea
                                                className={styles.textArea}
                                                value={otherText}
                                                placeholder={`Any additional comments${
                                                    includeCosts === '1'
                                                        ? ' & costs'
                                                        : ''
                                                }`}
                                                onChange={(e) =>
                                                    setOtherText(e.target.value)
                                                }
                                            />
                                        </div>
                                        {includeCosts === '1' ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginTop: '1rem',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        marginRight: '0.5rem',
                                                    }}
                                                >
                                                    &pound;
                                                </span>
                                                <div
                                                    className={
                                                        styles.fieldBlock
                                                    }
                                                >
                                                    <input
                                                        type='number'
                                                        min={0}
                                                        value={otherCost}
                                                        onChange={(e) =>
                                                            setOtherCost(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className={styles.buttonContainer}>
                                            <button
                                                className={
                                                    styles.otherInfoButton
                                                }
                                                type='button'
                                                onClick={handleOtherInfoSave}
                                                disabled={otherLoading}
                                            >
                                                Save Other Info{' '}
                                                <PulseLoader
                                                    loading={otherLoading}
                                                    color={'#ffffff'}
                                                    css={'margin-left: 8px'}
                                                    size={5}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.doorButtonsBottom}>
                                <div className={styles.photographContainer}>
                                    <img
                                        src={`data:image/jpeg;base64,${PHOTOGRAPH_ONE}`}
                                        alt='Upload photograph'
                                        style={{ marginRight: '1rem', width: '60px' }}
                                    />
                                    <input
                                        className={styles.photograph}
                                        type='file'
                                        ref={photoInputRef}
                                        onChange={(event) => handleAddPhoto(nextPhotoIndex, event)}
                                        placeholder='Choose File'
                                        disabled={isActiveRightDoor || !isPhotosEmpty}
                                        accept="image/png, image/jpeg"
                                    />
                                </div>
                                <div className={styles.doorPreviewsContainer}>
                                    {Object.keys(photoPreviews).map((index) => {
                                        if (photoPreviews[index]) {
                                            return (
                                                <div
                                                    key={index}
                                                    className={styles.previewContainer}
                                                >   
                                                    <img
                                                        className={styles.preview}
                                                        src={photoPreviews[index]}
                                                        alt='Photograph'
                                                    />
                                                    <button
                                                        className={styles.removePhoto}
                                                        onClick={() =>
                                                            handleRemovePhoto(
                                                                id,
                                                                index
                                                            )
                                                        }
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                                {isCompleted && (
                                    <div className={styles.buttonContainer}>
                                        <button
                                            className={styles.otherInfoButton}
                                            style={{ marginTop: '2rem' }}
                                            type='button'
                                            onClick={() =>
                                                setOtherInfo(!otherInfo)
                                            }
                                        >
                                            Other Info
                                        </button>
                                        {nextDoor && nextDoorId && (
                                            <Link
                                                className={
                                                    styles.nextDoorButton
                                                }
                                                style={{ marginTop: '2rem' }}
                                                to={`/survey/doors/${nextDoorId}`}
                                            >
                                                Next Door
                                            </Link>
                                        )}
                                        {finishDoor && (
                                            <button
                                                className={styles.finishButton}
                                                style={{ marginTop: '2rem' }}
                                                type='button'
                                                onClick={() =>
                                                    history.push(
                                                        '/survey/doors/'
                                                    )
                                                }
                                            >
                                                Finish Door
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <BottomNav />
        </>
    );
};
