import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import PulseLoader from 'react-spinners/PulseLoader';
import Localbase from 'localbase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import doorSections from '../../constants/doorSections';

import styles from './styles/Photo.module.css';

export default function Photo({ id, name, photo, isUploaded, uploaded, error }) {
    const [loading, setLoading] = useState(false);
    const [uploadedPhoto, setUploadedPhoto] = useState(false);
    const [errorPhoto, setErrorPhoto] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const db = new Localbase('napfis-webapp');

    const handlePhotoUpload = async (id, name, photo) => {
        const fd = new FormData();
        const fieldName = name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;

        fd.append(fieldName, photo);

        setLoading(true);

        try {
            const response = await axios.post('https://test.napfis.co.uk/app_handler.php', fd, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data',
                    'WG-Method': 'SAVE_DOOR_PHOTO',
                    'Wg-Doorid': id,
                    'Wg-Key': Cookie.get('accessToken')
                }
            });

            if (response.status === 200) {
                setUploadedPhoto(true);

                await db.collection('door-photos').doc({ id }).update({
                    uploaded: true,
                });
            } else {
                setErrorPhoto(true);
            }
        } catch (error) {
            setErrorPhoto(true);
        }

        setLoading(false);
    }

    const handleDeletePhoto = async () => {
        await db.collection('door-photos').doc({ id, name }).delete();

        setIsDeleted(true);
    }

    useEffect(() => {
        if (uploaded) {
            setUploadedPhoto(true);
            setErrorPhoto(false);
        } else if (error) {
            setErrorPhoto(true);
            setUploadedPhoto(false);
        }
    }, [uploaded, error]);

    if (!isDeleted) {
        return (
            <div className={styles.photoContainer}>
                <div className={styles.previewContainer}>
                    <img className={styles.preview} src={URL.createObjectURL(photo)} />
                    {isUploaded ? (
                        <button className={styles.removePhoto} onClick={handleDeletePhoto}>X</button>
                    ) : null}
                    {uploadedPhoto && (
                        <div className={styles.removedBackground}>
                            <FontAwesomeIcon icon={faCheck} color="#4BC33E" />
                        </div>
                    )}
                    {errorPhoto && (
                        <div className={styles.removedBackground}>
                            <FontAwesomeIcon icon={faTimes} color="#CD2121" />
                        </div>
                    )}
                    {isUploaded ? (
                        <div className={styles.uploadedPhoto}>
                            <FontAwesomeIcon icon={faCloudUploadAlt} color="#000" />
                        </div>
                    ) : null}
                </div>
                {!isUploaded || uploadedPhoto ? (
                    <button className={styles.uploadButton} onClick={() => handlePhotoUpload(id, name, photo)}>{loading ? 'Uploading...' : 'Upload'}</button>
                ) : null}
            </div>
        )
    }

    return null;
}