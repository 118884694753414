import React, { Fragment, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Localbase from 'localbase';
import axios from 'axios';
import Cookie from 'js-cookie';

import { clearSurvey } from '../../functions/utils';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { CLEAR_FAILED_DOOR_PHOTOS, CLEAR_SURVEY, REMOVE_FAILED_DOOR_PHOTO } from '../../types/surveyTypes';
import { CLEAR_DOORS } from '../../types/doorTypes';
import doorSections from '../../constants/doorSections';

import styles from './styles/SurveyComplete.css';

export const SurveyComplete = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const surveySelector = useSelector((state) => state.survey);
    const authSelector = useSelector((state) => state.auth);
    const { surveyKey, completed, failedDoorPhotos } = surveySelector;
    const { user } = authSelector;

    const handleStartAgain = () => {
        dispatch({ type: CLEAR_SURVEY });
        dispatch({ type: CLEAR_DOORS });

        clearSurvey();

        history.push('/survey/new');
    }

    async function uploadPhoto(document, doorId) {
        const fd = new FormData();
        const fieldName = document?.name === 'photograph_1' ? doorSections.PHOTOGRAPH_ONE : doorSections.PHOTOGRAPH_TWO;

        fd.append(fieldName, document?.photo);

        try {
            if (!document) throw new Error('No document passed');
            
            await axios.post('https://test.napfis.co.uk/app_handler.php', fd, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'multipart/form-data',
                    'WG-Method': 'SAVE_DOOR_PHOTO',
                    'Wg-Doorid': doorId,
                    'Wg-Key': Cookie.get('accessToken')
                }
            });
            
            dispatch({ type: REMOVE_FAILED_DOOR_PHOTO, payload: doorId });
        } catch (error) {
            console.log(error);
        }
    }

    const handleReupload = () => {
        const db = new Localbase('napfis-webapp');

        if(failedDoorPhotos.length > 0) {
            failedDoorPhotos.forEach(async (doorId) => {
                try {
                    const documents = await db.collection('door-photos').doc({ id: doorId }).get();

                    if (Array.isArray(documents)) {
                        documents.forEach(async (document) => {
                            await uploadPhoto(document, doorId);
                        });
                    } else {
                        await uploadPhoto(documents, doorId);
                    }
                } catch (error) {

                }
            });
        }
    }

    useEffect(() => {
        return () => {
            dispatch({ type: CLEAR_SURVEY });
            dispatch({ type: CLEAR_DOORS });
            dispatch({ type: CLEAR_FAILED_DOOR_PHOTOS });

            clearSurvey();
        }
    }, [])

    return (
        <Fragment>
            <Header>Survey Complete</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                {completed ? (
                    <Fragment>
                        {failedDoorPhotos.length > 0 && (
                            <div className={styles.uploadContainer}>
                                <p style={{ textAlign: 'center' }}>You have {failedDoorPhotos.length} door photo(s) that failed to upload.</p>
                                <div className="form__buttons">
                                    <button className="form__button" onClick={handleReupload}>Click Here to try again</button>
                                </div>
                            </div>
                        )}
                        <p style={{ marginTop: '2rem', textAlign: 'center' }}>To view a full report including a detailed schedule of works and suggested costs, please click "Download Survey (PDF)"</p>
                        <div className="form__buttons">
                            <a target="_blank" href={`https://www.napfis.co.uk/survey/v3/?${surveyKey}&${user?.secret_code}`} className="form__button">Download Survey (PDF)</a>
                        </div>
                        <div className="form__buttons">
                            <button className="form__button" onClick={handleStartAgain}>Click here to start a new survey</button>
                        </div>
                    </Fragment>
                ) : <Redirect to="/" />}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
